import React from "react"
import cx from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import { HeadingSm } from '../heading/Heading'
import { Link } from "gatsby"

import * as styles from './SiteFooter.module.css'

export function SiteFooter({ layoutClassName = undefined }) {
  return (
    <footer className={cx(styles.siteFooter, layoutClassName)} role="contentinfo">
      <div className={styles.siteFooterTop}>
        <div className={styles.contactinfo}>
          <div className={styles.heading}>
            <HeadingSm h={3}>Contactgegevens</HeadingSm>
          </div>
          <p className={styles.contactLink}>
            <span className={cx("material-icons")}>call</span>
            <a href="tel:+31642017721">+31 (0)6 42017721</a>
          </p>
          <p className={styles.contactLink}>
            <span className={cx("material-icons")}>email</span>
            <a href="mailto:info@empowermom.nl">info@empowermom.nl</a>
          </p>
        </div>

        <div className={styles.cooperation}>
          <div className={styles.heading}>
            <HeadingSm h={3}>Samenwerking</HeadingSm>
          </div>
          <a href="https://skjeugd.nl/" title="Ga naar skjeugd.nl" target="_blank" rel="noopener noreferrer" className={styles.cooperationLogo}>
            <StaticImage src="../../images/SKJ_logo.png" alt="" />
          </a>
        </div>

      </div>

      <div className={styles.siteFooterBottom}>
        <p className={styles.copyright}>© {new Date().getFullYear()} empowermom</p>
        <div className={styles.siteFooterNav}>
          <Link to="/klachtenregeling" className={styles.siteFooterNavLink}>Klachtenregeling</Link>
          <Link to="/privacyverklaring" className={styles.siteFooterNavLink}>Privacyverklaring</Link>
        </div>
      </div>
    </footer>
  )
}

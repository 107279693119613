// extracted by mini-css-extract-plugin
export var siteFooter = "SiteFooter-module--siteFooter--58NAG";
export var siteFooterTop = "SiteFooter-module--siteFooterTop--mH3Z6";
export var contactinfo = "SiteFooter-module--contactinfo--PqqqY";
export var cooperation = "SiteFooter-module--cooperation--Crma3";
export var cooperationLogo = "SiteFooter-module--cooperationLogo--VBDTS";
export var siteFooterBottom = "SiteFooter-module--siteFooterBottom--bC6IX";
export var siteFooterNav = "SiteFooter-module--siteFooterNav--eQ-vG";
export var heading = "SiteFooter-module--heading--uKObE";
export var contactLink = "SiteFooter-module--contactLink--2b5A7";
export var materialIcons = "SiteFooter-module--material-icons--xlXld";
export var copyright = "SiteFooter-module--copyright--dR7da";
export var siteFooterNavLink = "SiteFooter-module--siteFooterNavLink--Jcm47";
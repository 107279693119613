import cx from 'classnames'
import * as React from 'react'
import * as styles from './Heading.module.css'

export function HeadingXs({ h = undefined, children, layoutClassName = undefined}) {
  return <HeadingBase className={cx(styles.headingXs, layoutClassName)} {...{ h, children }} />
}

export function HeadingSm({ h = undefined, children }) {
  return <HeadingBase className={styles.headingSm} {...{ h, children }} />
}

export function HeadingMd({ h = undefined, children }) {
  return <HeadingBase className={styles.headingMd} {...{ h, children }} />
}

export function HeadingLg({ h = undefined, children }) {
  return <HeadingBase className={styles.headingLg} {...{ h, children }} />
}

export function HeadingXl({ h = undefined, children }) {
  return <HeadingBase className={styles.headingXl} {...{ h, children }} />
}

const hRenderers = {
  1: ({ children, ...props }) => <h1 {...props}>{children}</h1>,
  2: ({ children, ...props }) => <h2 {...props}>{children}</h2>,
  3: ({ children, ...props }) => <h3 {...props}>{children}</h3>,
  default: ({ children, ...props }) => <strong {...props}>{children}</strong>
}

function HeadingBase({ h, children, className }) {
  const HBase = hRenderers[h ?? 'default']
  return HBase && (
    <HBase className={cx(styles.heading, className)} {...{ children }} />
  )
}

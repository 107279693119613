import React from "react"
import cx from 'classnames'
import { useBodyScrollLock } from '../../machinery/useBodyScrollLock'
import { Navigation } from '../navigation/Navigation'
import { Link } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import logo from "../../images/logo-empowermom.svg"
import * as styles from './SiteHeader.module.css'

export function SiteHeader ({ layoutClassName = undefined }) {
  const breakpoints = useBreakpoint();
  const [expanded, setExpanded] = React.useState(false)
  const { ref: bodyScrollLockRef } = useBodyScrollLock({ enabled: expanded })

  return (
    <header className={cx(styles.siteHeader, layoutClassName)} role="banner">
      <Link to="/" title="Home" className={styles.logo}>
        <img src={logo} alt="empowermom" width="484" height="95" />
      </Link>
      <div className={styles.nav} ref={bodyScrollLockRef}>
        <Navigation {...{ expanded }} onExpandedChange={value => setExpanded(value)} />
        {breakpoints.md ? <Hamburger /> : null}
      </div>
    </header>
  )

  function Hamburger () {
    return (
      <button type="button" onClick={() => setExpanded(x => !x)} className={styles.hamburger}>
        <span className={cx('u-screenreader-text')}>Open menu</span>
      </button>
    )
  }
}
